import React from 'react'
import { useMediaQueries } from '@react-hook/media-query'
import useHasMounted from '../../hooks/useHasMounted'

const Header = ({
  correoHeader,
  telefonoHeader,
  mensajesHeader,
  linkLinkedin,
  linkFacebook,
  linkTwitter,
  linkInstagram,
}) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: 'only screen and (max-width: 992px)',
  })

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    !isMobile && (
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="items d-flex py-3">
            {correoHeader != null && (
              <a
                className="direccionesUl-li--a pe-4"
                href={`mailto:${correoHeader}`}
              >
                <img className="px-1" src="/emailGreen.svg" alt="email-icon" />
                {correoHeader}
              </a>
            )}
            {telefonoHeader != null && (
              <a
                className="direccionesUl-li--a pe-4"
                href={`tel:${telefonoHeader}`}
              >
                <img className="px-1" src="/phone.svg" alt="phone-icon" />
                {telefonoHeader}
              </a>
            )}
            {mensajesHeader != null && (
              <a
                className="direccionesUl-li--a pe-4"
                href={`https://wa.me/57${mensajesHeader}/`}
              >
                <img
                  className="px-1"
                  src="/messenger_green.svg"
                  alt="messenger-icon"
                />
                {mensajesHeader}
              </a>
            )}
          </div>
          <div className="items d-flex py-3">
            {linkLinkedin != null && (
              <div className="pe-3">
                <a
                  className="redesUl-li--a"
                  href={linkLinkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="redes-iconos"
                    src="/linkedin_green.svg"
                    alt="linkedin-icon"
                  />
                </a>
              </div>
            )}
            {linkFacebook != null && (
              <div className="pe-3">
                <a
                  className="redesUl-li--a"
                  href={linkFacebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="redes-iconos"
                    src="/facebook_green.svg"
                    alt="facebook-icon"
                  />
                </a>
              </div>
            )}
            {linkTwitter != null && (
              <div className="pe-3">
                <a
                  className="redesUl-li--a"
                  href={linkTwitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="redes-iconos"
                    src="/twitter_green.svg"
                    alt="twitter-icon"
                  />
                </a>
              </div>
            )}
            {linkInstagram != null && (
              <div className="pe-3">
                <a
                  className="redesUl-li--a"
                  href={linkInstagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="redes-iconos"
                    src="/instagram_green.svg"
                    alt="instagram-icon"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default Header
