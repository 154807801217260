import React from 'react'
const Footer = ({
  logoFooter,
  altFooter,
  correo1,
  correo2,
  correo3,
  departamento,
  direccion,
  enableFooter,
  footerTitle,
  footerParagrah,
  linkFacebook,
  linkInstagram,
  linkLinkedin,
  linkTwitter,
}) => {
  return (
    <footer className="footer">
      <div className="container pt-5 pb-2">
        <div className="my-lg-3 py-4 mx-4">
          {logoFooter != null && (
            <div className="footer-logo  mb-2 d-flex justify-content-center justify-content-md-start col-12">
              <img
                src={logoFooter}
                alt={altFooter}
                className="img-fluid col-9 col-md-5 col-lg-3 px-lg-3"
              />
            </div>
          )}
          <div className="footer-group d-flex flex-column align-items-center flex-lg-row justify-content-lg-between align-items-lg-start col-12 ">
            <div className="footer-group--datos col-11 col-lg-4">
              <ul className="footer-groupUl p-0">
                {direccion != null && (
                  <li className="footer-groupUl--li-enlaces pb-1">
                    <img
                      className="pe-2"
                      src="/directions.svg"
                      alt="direction_white"
                    />
                    <a href="https://www.google.com/maps/@9.564084,-73.3321048,3a,75y,219.82h,82.18t/data=!3m6!1e1!3m4!1se3CBt5EkLpHMNGsN-z2VYQ!2e0!7i13312!8i6656">
                      {direccion}
                    </a>
                  </li>
                )}
                {departamento != null && (
                  <li className="footer-groupUl--li-enlaces py-1">
                    <img className="pe-2" src="/map.svg" alt="map_white" />
                    <a href="https://es.wikipedia.org/wiki/La_Jagua_de_Ibirico">
                      {departamento}
                    </a>
                  </li>
                )}
                {correo1 != null && (
                  <li className="footer-groupUl--li-enlaces py-1">
                    <img className="pe-2" src="/email.svg" alt="email_white" />
                    <a href="mailto:milciades.suarez@msaenergy.co">{correo1}</a>
                  </li>
                )}
                {correo2 != null && (
                  <li className="footer-groupUl--li-enlaces py-1">
                    <img className="pe-2" src="/email.svg" alt="email_white" />
                    <a href="mailto:francisco.chimbi@msaenergy.co">{correo2}</a>
                  </li>
                )}
                {correo3 != null && (
                  <li className="footer-groupUl--li-enlaces py-1">
                    <img className="pe-2" src="/email.svg" alt="email_white" />
                    <a href="mailto:lorimel.abril@msaenergy.co">{correo3}</a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer-group--inicio col-10 col-lg-2 d-flex flex-column justify-content-between mt-5 mt-lg-0">
              <div className="inicio ">
                <a className="footer-enlaces" href="/">
                  Inicio
                </a>
              </div>
              <div className="nosotros mt-4 my-lg-0">
                <a className="footer-enlaces">Nosotros</a>
                <ul className="footer-groupUl ">
                  <li className="footer-groupUl--li">
                    <a href="/nosotros">Historia</a>
                  </li>
                  <li className="footer-groupUl--li">
                    <a href="/mision">Mision y Vision</a>
                  </li>
                  <li className="footer-groupUl--li">
                    <a href="/politicas-calidad">Calidad</a>
                  </li>
                  <li className="footer-groupUl--li">
                    <a href="/nuestros-valores">Valores</a>
                  </li>
                </ul>
              </div>
              <div className="contacto mb-4 my-lg-0">
                <a className="footer-enlaces" href="/contacto">
                  Contacto
                </a>
              </div>
            </div>
            <div className="footer-group--HSEQ col-10 col-lg-3 d-flex flex-column justify-content-between mb-5 mb-lg-0">
              <div className="HSEQ">
                <a className="footer-enlaces" href="/hseq">
                  HSEQ
                </a>
              </div>
              <div className="servicios">
                <a className="footer-enlaces" href="/">
                  Servicios
                </a>
                <ul className="footer-groupUl">
                  <li className="footer-groupUl--li">
                    <a href="/">Operacion y Mantenimiento</a>
                  </li>
                  <li className="footer-groupUl--li">
                    <a href="/">Apoyo y Soporte</a>
                  </li>
                </ul>
              </div>
              <div className="blogs">
                <a className="footer-enlaces" href="/blog">
                  Blogs
                </a>
              </div>
            </div>
            <div className="footer-group--redes d-flex flex-column align-items-center align-items-lg-start col-11 col-lg-3 mt-5 my-lg-0">
              <div className="redes-texto text-center text-lg-start d-flex flex-column align-items-center align-items-lg-start">
                {footerTitle != null && (
                  <h5 className="col-12">{footerTitle}</h5>
                )}
                {footerParagrah != null && (
                  <p className="col-10">{footerParagrah}</p>
                )}
              </div>
              <div className="redes-imagenes col-8 col-lg-8">
                <ul className="footer-groupUl d-flex justify-content-between align-items-center p-0">
                  {linkLinkedin != null && (
                    <li>
                      <a href={linkLinkedin} target="_blank">
                        <img src="/LinkedIN_white.svg" alt="linkedin_white" />
                      </a>
                    </li>
                  )}
                  {linkFacebook != null && (
                    <li>
                      <a href={linkFacebook} target="_blank">
                        <img src="/Facebook_white.svg" alt="facebook_white" />
                      </a>
                    </li>
                  )}
                  {linkTwitter != null && (
                    <li>
                      <a href={linkTwitter} target="_blank">
                        <img src="/Twitter_white.svg" alt="twitter_white" />
                      </a>
                    </li>
                  )}
                  {linkInstagram != null && (
                    <li>
                      <a href={linkInstagram} target="_blank">
                        <img src="/Instagram_white.svg" alt="instagram_white" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-msg col-11 pt-5">
            <p className="text-center">
              {new Date().getFullYear()} - Todos los Derechos Reservados - Hecha
              con ❤️ por Vaiavaia Studio / StarkLab.co
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
