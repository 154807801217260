import React, { useEffect } from 'react'
import Header from 'components/header'
import Footer from 'components/footer'
import sal from 'sal.js'

const Layout = ({ header, footer, children }) => {
  useEffect(() => {
    sal()
  }, [])

  return (
    <>
      {header?.enableHeader && (
        <Header
          correoHeader={header?.correo}
          telefonoHeader={header?.telefono}
          mensajesHeader={header?.messenger}
          linkLinkedin={header?.linkLinkedin}
          linkFacebook={header?.linkFacebook}
          linkTwitter={header?.linkTwitter}
          linkInstagram={header?.linkInstagram}
        />
      )}

      <main>{children}</main>
      {footer?.enableFooter && (
        <Footer
          logoFooter={footer?.logoEmpresaFooter?.image?.asset?.url}
          altFooter={footer?.logoEmpresaFooter?.alt}
          correo1={footer?.correo1}
          correo2={footer?.correo2}
          correo3={footer?.correo3}
          departamento={footer?.departamento}
          direccion={footer?.direccion}
          enableFooter={footer?.enableFooter}
          footerTitle={footer?.footerTitle}
          footerParagrah={footer?.footerParagrah}
          linkLinkedin={header?.linkLinkedin}
          linkFacebook={header?.linkFacebook}
          linkTwitter={header?.linkTwitter}
          linkInstagram={header?.linkInstagram}
        />
      )}
    </>
  )
}

export default Layout
