import React from 'react'
import { useMediaQueries } from '@react-hook/media-query'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { navigate } from 'gatsby'

const HeroComponent = ({
  navbar,
  navbarAlt,
  imageBackground,
  title,
  subTitle,
  btnText,
  btnEnable,
  btnLink,
  location,
}) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: 'only screen and (max-width: 992px)',
  })

  return (
    <div
      className="container-fluid bg-imagen"
      style={
        imageBackground && {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${imageBackground})`,
        }
      }
    >
      <div className="row">
        <nav
          className="navbar navbar-expand-lg my-3"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="container">
            {!isMobile && (
              <a href="/" className="navbar-brand">
                <img src={navbar} alt={navbarAlt} />
              </a>
            )}
            <button
              className="navbar-toggler ms-auto "
              id="btn-hamburger"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <img
                  src="/bars-solid.svg"
                  id="btn-hamburger--icon"
                  alt="hamburger-icon"
                />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li
                  className={classNames({
                    'nav-item px-3': true,
                    'nav-item-active': location === '/',
                  })}
                >
                  <a href="/" className="nav-link hero-nav" aria-current="page">
                    Inicio
                  </a>
                </li>
                <li
                  className={classNames({
                    'nav-item dropdown px-3': true,
                    'nav-item-active':
                      location === '/nosotros/' ||
                      location === '/mision/' ||
                      location === '/politicas-calidad/' ||
                      location === '/nuestros-valores/',
                  })}
                >
                  <a
                    className="nav-link dropdown-toggle hero-nav"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Nosotros
                  </a>
                  <ul
                    className="dropdown-menu hero-nav"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="dropdown-menu--li hero-nav">
                      <a className="dropdown-item hero-nav" href="/nosotros">
                        Historia
                      </a>
                    </li>
                    <li className="dropdown-menu--li hero-nav">
                      <a className="dropdown-item hero-nav" href="/mision">
                        Mision y Vision
                      </a>
                    </li>
                    <li className="dropdown-menu--li hero-nav">
                      <a
                        className="dropdown-item hero-nav"
                        href="/politicas-calidad"
                      >
                        Políticas
                      </a>
                    </li>
                    <li className="dropdown-menu--li hero-nav">
                      <a
                        className="dropdown-item hero-nav"
                        href="/nuestros-valores"
                      >
                        Valores
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={classNames({
                    'nav-item dropdown px-3': true,
                    'nav-item-active':
                      location === '/operacion-mantenimiento/' ||
                      location === '/apoyo-soporte/',
                  })}
                >
                  <a
                    className="nav-link dropdown-toggle hero-nav"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Servicios
                  </a>
                  <ul
                    className="dropdown-menu hero-nav"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="dropdown-menu--li hero-nav">
                      <a
                        className="dropdown-item hero-nav"
                        href="/operacion-mantenimiento"
                      >
                        Operacion y Mantenimiento
                      </a>
                    </li>
                    <li className="dropdown-menu--li hero-nav">
                      <a
                        className="dropdown-item hero-nav"
                        href="/apoyo-soporte"
                      >
                        Apoyo y Soporte
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={classNames({
                    'nav-item px-3': true,
                    'nav-item-active': location === '/hseq/',
                  })}
                >
                  <a href="/hseq" className="nav-link hero-nav">
                    HSEQ
                  </a>
                </li>
                <li
                  className={classNames({
                    'nav-item px-3': true,
                    'nav-item-active': location === '/contacto/',
                  })}
                >
                  <a href="/contacto" className="nav-link hero-nav">
                    Contacto
                  </a>
                </li>
                <li
                  className={classNames({
                    'nav-item px-3': true,
                    'nav-item-active': location === '/blog/',
                  })}
                >
                  <a href="/blog" className="nav-link hero-nav">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div
        className={classNames({
          'hero-container row d-flex justify-content-center align-items-end align-items-lg-center pb-5': true,
          'hero-container-execHeader': isMobile,
        })}
      >
        {isMobile && (
          <div className="icon d-flex justify-content-center align-items-center">
            <a href="/">
              {/* <img src="Logo.svg" alt="Logo" /> */}
              <img src={navbar} alt={navbarAlt} />
            </a>
          </div>
        )}
        <div className="hero-text text-center col-11 col-lg-6 animate__animated animate__bounce">
          <h1 className="hero-text--title col-10 col-lg-12 mx-auto">{title}</h1>
          <p className="hero-text--p ms-auto mt-3">{subTitle}</p>
          {btnEnable && (
            <button
              className="hero-text--button btn col-8 col-lg-6 my-3 py-3 py-lg-2 rojo"
              onClick={() => {
                navigate(`/${btnLink}`)
              }}
            >
              {btnText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

HeroComponent.propTypes = {
  imageBackground: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnCallToAction: PropTypes.func.isRequired,
  btnEnable: PropTypes.bool.isRequired,
}

export default HeroComponent
